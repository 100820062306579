var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticStyle:{"padding":"0px","height":"100%"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%"}},[_c('div',[_c('div',{staticClass:"Enter-top"},[_c('a-breadcrumb',{staticStyle:{"margin":"18px 0"}},[_c('a-breadcrumb-item',[_vm._v("档案管理")]),_c('a-breadcrumb-item',[_vm._v("集中器")])],1)],1)])]),_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background":"#fff","margin":"0","overflow":"auto"}},[_c('div',{staticClass:"search-wrap flex-d-row"},[_c('div',{staticStyle:{"width":"170px","border-right":"1px solid #d9d9d9"}},[_c('div',{staticClass:"regionleft"},[_vm._v("区域信息")]),_c('a-tree',{attrs:{"replace-fields":_vm.replaceFields,"tree-data":_vm.treeData,"value":"title","autoExpandParent":false},on:{"select":_vm.onSelect}})],1),_c('div',{staticStyle:{"flex":"1","padding":"24px"}},[_c('div',{staticClass:"in-top"},[_c('a-form',{staticClass:"ant-advanced-search-form",attrs:{"form":_vm.fom},on:{"submit":_vm.handleSearch}},[_c('a-row',{staticClass:"flex-d-row",staticStyle:{"flex-wrap":"wrap"},attrs:{"gutter":24}},[_vm._l((_vm.textlist),function(i,index){return _c('a-col',{key:index},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":i}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        `${i}`,
                        {
                          rules: [],
                        },
                      ]),expression:"[\n                        `${i}`,\n                        {\n                          rules: [],\n                        },\n                      ]"}],staticStyle:{"width":"178px"},attrs:{"placeholder":'请输入' + i}})],1)],1)}),_c('a-col',{staticClass:"a-col-top"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 搜索 ")]),_c('a-button',{style:({ marginLeft: '8px' }),on:{"click":_vm.handleReset}},[_vm._v(" 清空 ")])],1)],2)],1),_c('a-button',{on:{"click":_vm.showDrawer}},[_vm._v("添加")])],1),_c('a-table',{attrs:{"row-selection":_vm.rowSelection,"rowKey":"Id","pagination":false,"columns":_vm.columns,"data-source":_vm.jzqlist,"rowClassName":_vm.rowClassName},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a',{on:{"click":function($event){return _vm.onEdit(record)}}},[_vm._v("编辑  ")]),_c('a-popconfirm',{attrs:{"title":"确认要删除吗?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.deljzq(record.baseId)},"cancel":_vm.cancel}},[_c('a',{attrs:{"href":"#"}},[_vm._v("删除")])])]}}])}),_c('a-pagination',{attrs:{"page-size":_vm.params.pageSize,"show-quick-jumper":"","default-current":1,"total":_vm.total},on:{"change":_vm.onpagesize},model:{value:(_vm.params.current),callback:function ($$v) {_vm.$set(_vm.params, "current", $$v)},expression:"params.current"}})],1),_c('a-drawer',{attrs:{"title":_vm.title,"placement":"right","maskClosable":true,"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[(_vm.forms.id != '')?_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"id"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'id',
                  {
                    rules: [{ required: true, message: 'id不能为空' }],
                  },
                ]),expression:"[\n                  'id',\n                  {\n                    rules: [{ required: true, message: 'id不能为空' }],\n                  },\n                ]"}],attrs:{"disabled":true,"placeholder":"请输入id"}})],1):_vm._e(),(_vm.forms.id != '')?_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"baseId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'baseId',
                  {
                    rules: [{ required: true, message: 'baseId不能为空' }],
                  },
                ]),expression:"[\n                  'baseId',\n                  {\n                    rules: [{ required: true, message: 'baseId不能为空' }],\n                  },\n                ]"}],attrs:{"placeholder":"请输入baseId"}})],1):_vm._e(),_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'barCode',
                  {
                    rules: [{ required: true, message: '编码不能为空' }],
                  },
                ]),expression:"[\n                  'barCode',\n                  {\n                    rules: [{ required: true, message: '编码不能为空' }],\n                  },\n                ]"}],attrs:{"placeholder":"请输入编码"}})],1),_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"通讯方案类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'commSchemeCode',
                  {
                    rules: [
                      { required: true, message: '通讯方案类型不能为空!' },
                    ],
                  },
                ]),expression:"[\n                  'commSchemeCode',\n                  {\n                    rules: [\n                      { required: true, message: '通讯方案类型不能为空!' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"请选择通讯方案类型"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 普通方案 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" LoRa方案 ")]),_c('a-select-option',{attrs:{"value":"3"}},[_vm._v(" LoRaWAN方案n ")]),_c('a-select-option',{attrs:{"value":"4"}},[_vm._v(" NB-IoT 方案 ")])],1)],1),_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"集中器地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'address',
                  {
                    rules: [
                      {
                        required: true,
                        message: '集中器地址不能为空',
                      },
                    ],
                  },
                ]),expression:"[\n                  'address',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '集中器地址不能为空',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"请输入集中器地址"}})],1),_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: '密码不能为空',
                      },
                    ],
                  },
                ]),expression:"[\n                  'password',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '密码不能为空',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"请输入密码"}})],1),_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"所属区域"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'areaId',
                  {
                    rules: [{ required: true, message: '所属区域不能为空!' }],
                  },
                ]),expression:"[\n                  'areaId',\n                  {\n                    rules: [{ required: true, message: '所属区域不能为空!' }],\n                  },\n                ]"}],attrs:{"placeholder":"请选择所属区域"}},_vm._l((_vm.arealist),function(item){return _c('a-select-option',{key:item.ID,attrs:{"value":item.ID}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),1)],1),_c('a-form-item',{staticStyle:{"height":"65px"},attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'explain',
                  {
                    rules: [
                      {
                        required: true,
                        message: '描述不能为空',
                      },
                    ],
                  },
                ]),expression:"[\n                  'explain',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: '描述不能为空',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"请输入描述"}})],1),_c('div',{staticClass:"subbtn"},[_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v(" 确定 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.onClose}},[_vm._v("取消")])],1)],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }