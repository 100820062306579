<template>
  <a-layout style="padding: 0px; height: 100%">
    <a-layout-content
      style="background-color: #fff; line-height: 60px; margin: 0; width: 100%"
    >
      <div>
        <div class="Enter-top">
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>档案管理</a-breadcrumb-item>
            <a-breadcrumb-item>集中器</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <!-- <a-tabs
          style="margin-left: 10px"
          default-active-key="1"
          @change="callback"
        >
          <a-tab-pane key="1" tab="报警方案"> </a-tab-pane>
          <a-tab-pane key="2" tab="电费"> </a-tab-pane>
          <a-tab-pane key="3" tab="水费"> </a-tab-pane>
          <a-tab-pane key="4" tab="物业费"> </a-tab-pane>
        </a-tabs> -->
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content style="background: #fff; margin: 0; overflow: auto">
        <!-- 表格 -->
        <div class="search-wrap flex-d-row">
          <div style="width: 170px; border-right: 1px solid #d9d9d9">
            <div class="regionleft">区域信息</div>
            <a-tree
              :replace-fields="replaceFields"
              :tree-data="treeData"
              value="title"
              :autoExpandParent="false"
              @select="onSelect"
            >
            </a-tree>
          </div>
          <div style="flex: 1; padding: 24px">
            <div class="in-top">
              <a-form
                class="ant-advanced-search-form"
                :form="fom"
                @submit="handleSearch"
              >
                <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
                  <a-col v-for="(i, index) in textlist" :key="index">
                    <a-form-item style="margin-bottom: 0" :label="i">
                      <a-input
                        style="width: 178px"
                        v-decorator="[
                          `${i}`,
                          {
                            rules: [],
                          },
                        ]"
                        :placeholder="'请输入' + i"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col class="a-col-top">
                    <a-button type="primary" html-type="submit">
                      搜索
                    </a-button>
                    <a-button
                      :style="{ marginLeft: '8px' }"
                      @click="handleReset"
                    >
                      清空
                    </a-button>
                  </a-col>
                </a-row>
              </a-form>
              <a-button @click="showDrawer">添加</a-button>
            </div>
            <a-table
              :row-selection="rowSelection"
              rowKey="Id"
              :pagination="false"
              :columns="columns"
              :data-source="jzqlist"
              :rowClassName="rowClassName"
            >
              <template slot="action" slot-scope="text, record">
                <a @click="onEdit(record)">编辑&nbsp;&nbsp;</a>
                <a-popconfirm
                  title="确认要删除吗?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="deljzq(record.baseId)"
                  @cancel="cancel"
                >
                  <a href="#">删除</a>
                </a-popconfirm>
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="1"
              :total="total"
              @change="onpagesize"
            />
          </div>
          <a-drawer
            :title="title"
            placement="right"
            :maskClosable="true"
            :visible="visible"
            @close="onClose"
          >
            <a-form :form="form" @submit="handleSubmit">
              <a-form-item
                v-if="forms.id != ''"
                style="height: 65px"
                label="id"
              >
                <a-input
                  :disabled="true"
                  v-decorator="[
                    'id',
                    {
                      rules: [{ required: true, message: 'id不能为空' }],
                    },
                  ]"
                  placeholder="请输入id"
                />
              </a-form-item>
              <a-form-item
                v-if="forms.id != ''"
                style="height: 65px"
                label="baseId"
              >
                <a-input
                  v-decorator="[
                    'baseId',
                    {
                      rules: [{ required: true, message: 'baseId不能为空' }],
                    },
                  ]"
                  placeholder="请输入baseId"
                />
              </a-form-item>
              <a-form-item style="height: 65px" label="编码">
                <a-input
                  v-decorator="[
                    'barCode',
                    {
                      rules: [{ required: true, message: '编码不能为空' }],
                    },
                  ]"
                  placeholder="请输入编码"
                />
              </a-form-item>
              <a-form-item style="height: 65px" label="通讯方案类型">
                <a-select
                  v-decorator="[
                    'commSchemeCode',
                    {
                      rules: [
                        { required: true, message: '通讯方案类型不能为空!' },
                      ],
                    },
                  ]"
                  placeholder="请选择通讯方案类型"
                >
                  <a-select-option value="1"> 普通方案 </a-select-option>
                  <a-select-option value="2"> LoRa方案 </a-select-option>
                  <a-select-option value="3"> LoRaWAN方案n </a-select-option>
                  <a-select-option value="4"> NB-IoT 方案 </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item style="height: 65px" label="集中器地址">
                <a-input
                  v-decorator="[
                    'address',
                    {
                      rules: [
                        {
                          required: true,
                          message: '集中器地址不能为空',
                        },
                      ],
                    },
                  ]"
                  placeholder="请输入集中器地址"
                />
              </a-form-item>
              <a-form-item style="height: 65px" label="密码">
                <a-input
                  v-decorator="[
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: '密码不能为空',
                        },
                      ],
                    },
                  ]"
                  placeholder="请输入密码"
                />
              </a-form-item>
              <a-form-item style="height: 65px" label="所属区域">
                <a-select
                  v-decorator="[
                    'areaId',
                    {
                      rules: [{ required: true, message: '所属区域不能为空!' }],
                    },
                  ]"
                  placeholder="请选择所属区域"
                >
                  <a-select-option
                    v-for="item in arealist"
                    :key="item.ID"
                    :value="item.ID"
                  >
                    {{ item.Name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item style="height: 65px" label="描述">
                <a-input
                  v-decorator="[
                    'explain',
                    {
                      rules: [
                        {
                          required: true,
                          message: '描述不能为空',
                        },
                      ],
                    },
                  ]"
                  placeholder="请输入描述"
                />
              </a-form-item>
              <div class="subbtn">
                <a-button html-type="submit" type="primary"> 确定 </a-button>
                <a-button @click="onClose" style="margin-left: 10px"
                  >取消</a-button
                >
              </div>
            </a-form>
          </a-drawer>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '编码',
    dataIndex: 'BarCode',
  },
  {
    title: '集中器地址',
    dataIndex: 'Address',
  },
  {
    title: '描述',
    dataIndex: 'Explan',
  },
  {
    title: '添加时间',
    dataIndex: 'AddTime',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const treeData = [
  {
    title: '东海欧龙世纪城',
    key: '0-0',
    children: [
      {
        title: '01F',
        key: '0-0-0',
        children: [
          { title: '101铺位', key: '101铺位' },
          { title: '102铺位', key: '0-0-0-1' },
          { title: '103铺位', key: '0-0-0-2' },
        ],
      },
      {
        title: '02F',
        key: '0-0-1',
        children: [
          { title: '201铺位', key: '0-0-1-0' },
          { title: '202铺位', key: '0-0-1-1' },
          { title: '203铺位', key: '0-0-1-2' },
        ],
      },
      {
        title: '03F',
        key: '0-0-1',
        children: [
          { title: '301铺位', key: '0-0-1-0' },
          { title: '302铺位', key: '0-0-1-1' },
          { title: '303铺位', key: '0-0-1-2' },
        ],
      },
    ],
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      treeData,
      columns,
      replaceFields: {
        children: 'children',
        title: 'title',
        value: 'key',
      },
      textlist: ['搜索'],
      visible: false,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      callbacks: 1,
      fom: '',
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      title: '',
      params: {
        current: 1,
        pageSize: 10,
        parentId: 0,
      },
      total: 0,
      jzqlist: [],
      arealist: [],
      forms: {
        id: '',
        baseId: '',
        barCode: 0, //编码
        commSchemeCode: '', //通讯方案类型
        address: '', //集中器地址
        password: '', //密码
        areaId: '', //所属区域Id
        explain: '',
      },
    }
  },
  filters: {},
  methods: {
    handleSearch() {},
    handleReset() {},
    onpagesize(e) {
      this.params.current = e
      this.getjzqsearch()
    },
    handleSubmit() {
      this.form.validateFields((err, fieldsValue) => {
        if (err) {
          return
        }
        if (this.title == '新增区域') {
          this.getjzqadd(fieldsValue)
        } else {
          this.putjzqupdate(fieldsValue)
        }
      })
    },
    onEdit(e) {
      //   console.log(e);
      this.forms.id = e.Id
      this.title = '修改区域'
      this.visible = true
      this.getareasearch()
      setTimeout(() => {
        this.form.setFieldsValue({
          id: e.Id,
          barCode: e.BarCode,
          commSchemeCode: e.EquipmentTypeID + '',
          address: e.Address,
          password: e.Password,
          //   areaId: e.baseId,
          baseId: e.baseId,
          explain: e.Explan,
        })
      }, 100)
    },
    showDrawer() {
      this.title = '新增区域'
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.form.resetFields()
      this.forms.id = ''
    },
    onSelect(selectedKeys, info) {
      console.log(selectedKeys)
      console.log(info)
      //   console.log(info.node._props.dataRef);
    },
    callback(e) {
      this.callbacks = e
    },
    async getjzqsearch() {
      try {
        const res = await http.getjzqsearch(this.params)
        const { success, data } = res.data
        if (success) {
          this.jzqlist = data.data.DataLst
          this.total = data.data.Count
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getareasearch() {
      try {
        const res = await http.getareasearch(this.params)
        const { success, data } = res.data
        if (success) {
          this.arealist = data.data.DataLst
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    // 新增
    async getjzqadd(form) {
      try {
        const res = await http.getjzqadd(form)
        const { success, msg } = res.data
        if (success) {
          this.onClose()
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    //编辑
    async putjzqupdate(form) {
      try {
        const res = await http.putjzqupdate(form)
        const { success, msg } = res.data
        if (success) {
          this.onClose()
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },

    async deljzq(id) {
      try {
        const res = await http.deljzq(id)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
        console.log('error:' + ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getjzqsearch()
      }, 100)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    // this.getjzqsearch();
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
}
.Enter-top {
  padding: 0 24px;
}
.in-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.regionleft {
  height: 40px;
  line-height: 40px;
  background: #fafafa;
  border-bottom: #e8e8e8 1px solid;
  padding-left: 18px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-tabs-bar {
  margin: 0;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-form-item-label {
  text-align: left;
  height: 25px;
  line-height: 25px;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.a-col-top {
  padding-top: 28px;
}
.subbtn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  text-align: center;
  line-height: 50px;
  /* border-top: 1px solid #d9d9d9; */
}
</style>
